import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div className="logo-container">
      <Link to="/home">
        <img
          className="logo"
          src={`${process.env.PUBLIC_URL} /images/mta_logo.svg`}
          alt="logo"
        />
      </Link>
    </div>
  );
};

export default Logo;
