import React, { Component } from "react";
import Lunr from "lunr";
import backend from "./workers/tempBackend";
import footFix from "./workers/footFix";

class SearchResults extends Component {
  state = {
    results: [],
    dynamicContainer: {
      height: "0px",
      width: "100%",
    },
  };

  search = (categories, data) => {
    const idx = Lunr(function () {
      this.ref("title");
      this.field("title");
      this.field("keywords");
      //this.metadataWhitelist = ["position"];

      categories.forEach(function (cat) {
        backend.categories[cat].forEach(function (doc) {
          this.add(doc);
        }, this);
      }, this);
    });

    const results = idx.search(this.props.location.state.query);

    this.getResults(results, data);
  };

  getResults = (results, data) => {
    const final = [];

    results.forEach((result) => {
      data.forEach((dat) => {
        dat.forEach((d) => {
          if (result.ref === d.title) final.push(d);
        });
      });
    });

    console.log("Final Results: ", final);
    this.setState({ results: final });
  };

  resizeListener = () => {
    clearTimeout(this.resizeId);
    this.resizeId = setTimeout(this.doneResizing, 500);
  };

  doneResizing = () => {
    this.resetDynamicContainer();
  };

  resetDynamicContainer = () => {
    const dynamicContainer = {
      height: "0px",
      width: "100%",
    };

    this.setState({ dynamicContainer }, this.setFooterPosition);
  };

  setFooterPosition = () => {
    console.log("SETTING FOOTER: ", this.props.footRef);

    if (this.props.footRef) {
      setTimeout(() => {
        const dynamicContainer = footFix.footerPositionCalc(
          this.props.footRef,
          true
        );
        this.setState({ dynamicContainer });
      }, 200);
    } else {
      this.delay = setTimeout(() => {
        this.setFooterPosition();
        console.log("SETTING FOOTER DELAYED");
      }, 0);
    }
  };

  checkDom = () => {
    const navData = window.performance.getEntriesByType("navigation");

    if (navData.length > 0 && navData[0].loadEventEnd > 0) {
      this.resetDynamicContainer();
    }
  };

  componentDidMount() {
    this.checkDom();

    window.addEventListener("resize", this.resizeListener);
    window.addEventListener("load", this.resetDynamicContainer);

    const data = backend.getAllCategories();
    const categories = [];
    const arr = [];

    for (let category in data) {
      arr.push(data[category]);
      categories.push(category);
    }

    this.search(categories, arr);
  }

  componentWillUnmount() {
    if (this.delay) {
      clearTimeout(this.delay);
    }
    if (this.resizeId) {
      clearTimeout(this.resizeId);
    }
    window.removeEventListener("load", this.resetDynamicContainer);
    window.removeEventListener("resize", this.resizeListener);
  }

  render() {
    return (
      <React.Fragment>
        <div id="main-container" className="main-container">
          <h1 className="container-heading">
            Search Results: {this.props.location.state.query}
          </h1>
          <div className="section-container">
            {this.state.results.length > 0 ? (
              this.state.results.map((result, i) => {
                if (result.hasTalent) {
                  return (
                    //<Link to={category.route}>
                    <div className="category-container" key={i}>
                      <span className="category-container__line">
                        <h2 className="category-container__title">
                          {result.name}
                        </h2>
                      </span>
                      <img
                        className="category-container__image"
                        src={require(`../images/${result.image}`)}
                        alt={result.name}
                      />
                    </div>
                    //</Link>
                  );
                }
              })
            ) : (
              <div>
                There were no results for "
                <span className="search-none">
                  {this.props.location.state.query}
                </span>
                "
              </div>
            )}
          </div>
        </div>
        <div style={this.state.dynamicContainer}></div>
      </React.Fragment>
    );
  }
}

export default SearchResults;
