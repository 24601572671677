import React, { Component, createContext } from "react";

export const FooterContext = createContext();

class FooterProvider extends Component {
  state = {
    ref: null,
  };

  updateRef = (ref) => {
    console.log("UPDATING REF FROM CONTEXT: ", ref);
    this.setState({ ref });
  };

  render() {
    return (
      <FooterContext.Provider
        value={{ ...this.state, updateRef: this.updateRef }}
      >
        {this.props.children}
      </FooterContext.Provider>
    );
  }
}

export default FooterProvider;
