import React from "react";

const BackTop = () => {
  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return (
    <img
      className="back-to-top-button"
      src={require("../../images/arrow_button_top.svg")}
      onClick={topFunction}
    />
  );
};

export default BackTop;
