import React, { Component } from "react";
import footFix from "./workers/footFix";

class Contact extends Component {
  state = {
    dynamicContainer: {
      height: "0px",
      width: "100%",
    },
  };

  resizeListener = () => {
    clearTimeout(this.resizeId);
    this.resizeId = setTimeout(this.doneResizing, 500);
  };

  doneResizing = () => {
    this.resetDynamicContainer();
  };

  resetDynamicContainer = () => {
    const dynamicContainer = {
      height: "0px",
      width: "100%",
    };

    this.setState({ dynamicContainer }, this.setFooterPosition);
  };

  setFooterPosition = () => {
    console.log("SETTING FOOTER CALLED: ", this.props.footRef);

    if (this.props.footRef) {
      setTimeout(() => {
        const dynamicContainer = footFix.footerPositionCalc(
          this.props.footRef,
          true
        );
        this.setState({ dynamicContainer });
      }, 200);
    } else {
      this.delay = setTimeout(() => {
        this.setFooterPosition();
        console.log("DELAY!");
      }, 0);
    }
  };

  checkDom = () => {
    console.log("CHECK DOM");
    const navData = window.performance.getEntriesByType("navigation");

    if (navData.length > 0 && navData[0].loadEventEnd > 0) {
      this.resetDynamicContainer();
    }
  };

  componentDidMount() {
    this.checkDom();

    window.addEventListener("resize", this.resizeListener);
    window.addEventListener("load", this.resetDynamicContainer);
  }

  componentWillUnmount() {
    if (this.delay) {
      clearTimeout(this.delay);
    }
    if (this.resizeId) {
      clearTimeout(this.resizeId);
    }
    window.removeEventListener("load", this.resetDynamicContainer);
    window.removeEventListener("resize", this.resizeListener);
  }

  render() {
    return (
      <div className="info-container">
        {/* <div style={this.state.dynamicContainer.top}></div> */}
        <h1 className="info-container__heading">Contact MTA</h1>
        <p className="info-container__paragraph">
          <span className="info-container__span">Phone Number</span>
          <br />
          &#40;719&#41; 577-4704
        </p>
        <p className="info-container__paragraph">
          <span className="info-container__span">Fax Number</span>
          <br />
          &#40;719&#41; 520-1952
        </p>
        <p className="info-container__paragraph">
          <span className="info-container__span">Street Address</span>
          <br />
          332 W. Bijou St. Suite #108
          <br />
          Colorado Springs, Colorado 80905
        </p>
        <p className="info-container__paragraph">
          <span className="info-container__span">Email Address</span>
          <br />
          mtatalent@yahoo.com
        </p>
        <div style={this.state.dynamicContainer}></div>
      </div>
    );
  }
}

export default Contact;
