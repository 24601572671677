import React, { Component } from "react";
import { Link } from "react-router-dom";
import footFix from "./workers/footFix";
import backend from "./workers/tempBackend";

class Categories extends Component {
  state = {
    categories: [],
    title: "",
    info: [],
    layout: "",
    footerBoolean: false,
    dynamicContainer: {},
  };

  resizeListener = () => {
    clearTimeout(this.resizeId);
    this.resizeId = setTimeout(this.doneResizing, 500);
  };

  doneResizing = () => {
    this.resetDynamicContainer();
  };

  resetDynamicContainer = () => {
    const dynamicContainer =
      this.state.layout === "large"
        ? {
            top: { height: "0px", width: "100%" },
            bottom: { height: "0px", width: "100%" },
          }
        : {
            height: "0px",
            width: "100%",
          };

    console.log("DYNAMIC CONTAINER: ", dynamicContainer);

    this.setState({ dynamicContainer }, this.setFooterPosition);
  };

  setFooterPosition = () => {
    console.log("SETTING FOOTER CALLED: ", this.props.footRef);

    if (this.props.footRef) {
      setTimeout(() => {
        const dynamicContainer = footFix.footerPositionCalc(
          this.props.footRef,
          this.state.footerBoolean
        );
        this.setState({ dynamicContainer });
      }, 500);
    } else {
      this.delay = setTimeout(() => {
        this.setFooterPosition();
        console.log("DELAY!");
      }, 0);
    }
  };

  checkDom = () => {
    console.log("CHECK DOM");
    const navData = window.performance.getEntriesByType("navigation");

    if (navData.length > 0 && navData[0].loadEventEnd > 0) {
      this.resetDynamicContainer();
    }
  };

  addListeners = () => {
    this.checkDom();
    window.addEventListener("resize", this.resizeListener);
    window.addEventListener("load", this.resetDynamicContainer);
  };

  componentDidMount() {
    //const pathArray = http://localhost:8080/downloads/software
    const pathArray = this.props.location.pathname.split("/");
    const segment1 = pathArray[1]; //downloads
    //const segment2 = pathArray[2]; //software
    const categories = backend.getCategories(segment1);
    const { title, info } = backend.getTitles(segment1);

    const layout = categories.length > 4 ? "large" : "small";
    const footerBoolean = layout === "small" ? true : false;

    this.setState(
      { categories, title, info, layout, footerBoolean },
      this.addListeners
    );
  }

  componentWillUnmount() {
    if (this.delay) {
      clearTimeout(this.delay);
    }
    if (this.resizeId) {
      clearTimeout(this.resizeId);
    }
    window.removeEventListener("load", this.resetDynamicContainer);
    window.removeEventListener("resize", this.resizeListener);
  }

  render() {
    const { categories, info } = this.state;
    const isCategories = categories.length > 0;
    const hasInfo = info.length > 0;
    console.log("isCategories: ", isCategories);

    console.log("CATEGORIES GOT: ", this.state.categories);
    return (
      <React.Fragment>
        <div style={this.state.dynamicContainer.top}></div>
        <div id="main-container" className="main-container">
          <h1 className="container-heading">MTA {this.state.title}</h1>
          <div className="section-container">
            {isCategories
              ? categories.map((category, i) => {
                  if (category.hasTalent) {
                    return (
                      //<Link to={category.route}>
                      <div className="category-container" key={i}>
                        <span className="category-container__line">
                          <h2 className="category-container__title">
                            {category.name}
                          </h2>
                        </span>
                        <img
                          className="category-container__image"
                          src={require(`../images/${category.image}`)}
                          alt={`${category.name} Category`}
                        />
                      </div>
                      //</Link>
                    );
                  }
                })
              : null}
          </div>
          {hasInfo
            ? info.map((paragraph, i) => {
                return (
                  <React.Fragment>
                    <p className="category-description__text">{paragraph}</p>
                    <br />
                  </React.Fragment>
                );
              })
            : null}
        </div>
        {/* <div className="category-description">
          
        </div> */}
        <div style={this.state.dynamicContainer.bottom}></div>
        <div style={this.state.dynamicContainer}></div>
      </React.Fragment>
    );
  }
}

export default Categories;
