import React, { Component } from "react";
import footFix from "./workers/footFix";

class About extends Component {
  state = {
    dynamicContainer: {
      height: "0px",
      width: "100%",
    },
  };

  resizeListener = () => {
    clearTimeout(this.resizeId);
    this.resizeId = setTimeout(this.doneResizing, 500);
  };

  doneResizing = () => {
    this.resetDynamicContainer();
  };

  resetDynamicContainer = () => {
    const dynamicContainer = {
      height: "0px",
      width: "100%",
    };

    this.setState({ dynamicContainer }, this.setFooterPosition);
  };

  setFooterPosition = () => {
    console.log("SETTING FOOTER CALLED: ", this.props.footRef);

    if (this.props.footRef) {
      setTimeout(() => {
        const dynamicContainer = footFix.footerPositionCalc(
          this.props.footRef,
          true
        );
        this.setState({ dynamicContainer });
      }, 200);
    } else {
      this.delay = setTimeout(() => {
        this.setFooterPosition();
        console.log("DELAY!");
      }, 0);
    }
  };

  checkDom = () => {
    console.log("CHECK DOM");
    const navData = window.performance.getEntriesByType("navigation");

    if (navData.length > 0 && navData[0].loadEventEnd > 0) {
      this.resetDynamicContainer();
    }
  };

  componentDidMount() {
    this.checkDom();

    window.addEventListener("resize", this.resizeListener);
    window.addEventListener("load", this.resetDynamicContainer);
  }

  componentWillUnmount() {
    if (this.delay) {
      clearTimeout(this.delay);
    }
    if (this.resizeId) {
      clearTimeout(this.resizeId);
    }
    window.removeEventListener("load", this.resetDynamicContainer);
    window.removeEventListener("resize", this.resizeListener);
  }

  render() {
    return (
      <div className="info-container">
        {/* <div style={this.state.dynamicContainer.top}></div> */}
        <h1 className="info-container__heading">About MTA</h1>
        <p className="info-container__paragraph">
          MTA is a full-service talent and promotional agency doing business in
          the state of Colorado since 1967. We represent over 100 bands,
          specialty acts, and have produced 57 national concerts. We have an
          abundance of music and entertainment for night clubs, concerts,
          conventions, meetings, reunions, weddings and special events.
        </p>
        <p className="info-container__paragraph">
          MTA is the largest long-standing talent agency in the state of
          Colorado. Licensed with SAG (Screen Actors Guild) in Hollywood,
          California we represent acting talent for commercials, motion
          pictures, and television productions. We are franchised with the WGA
          (Writers Guild of America West Coast) and represent screenplay writers
          and market screenplays to feature film and television producers.
        </p>
        <p className="info-container__paragraph">
          MTA is family owned and operated by Dan Mattas, Lucy Mattas, and Adam
          Mattas. In 1990 Lucy Mattas was 1 of 5 founding members of the first
          Environmental Film Festival in the United States. MTA has a well
          earned reputation for professionalism and integrity. We look forward
          to working with you.
        </p>
        <div style={this.state.dynamicContainer}></div>
      </div>
    );
  }
}

export default About;
