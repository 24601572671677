import React, { Component } from "react";
import { Link } from "react-router-dom";
import backend from "./workers/tempBackend";
import footFix from "./workers/footFix";

class Home extends Component {
  state = {
    imagesTopRow: [],
    imagesBottomRow: [],
    dynamicContainer: {
      top: { height: "0px", width: "100%" },
      bottom: { height: "0px", width: "100%" },
    },
  };

  resizeListener = () => {
    clearTimeout(this.resizeId);
    this.resizeId = setTimeout(this.doneResizing, 500);
  };

  doneResizing = () => {
    this.resetDynamicContainer();
  };

  resetDynamicContainer = () => {
    const dynamicContainer = {
      top: { height: "0px", width: "100%" },
      bottom: { height: "0px", width: "100%" },
    };

    this.setState({ dynamicContainer }, this.setFooterPosition);
  };

  setFooterPosition = () => {
    console.log("SETTING FOOTER CALLED: ", this.props.footRef);

    if (this.props.footRef) {
      setTimeout(() => {
        const dynamicContainer = footFix.footerPositionCalc(this.props.footRef);
        this.setState({ dynamicContainer });
      }, 500);
    } else {
      this.delay = setTimeout(() => {
        this.setFooterPosition();
        console.log("DELAY!");
      }, 0);
    }
  };

  checkDom = () => {
    console.log("CHECK DOM");
    const navData = window.performance.getEntriesByType("navigation");

    if (navData.length > 0 && navData[0].loadEventEnd > 0) {
      this.resetDynamicContainer();
    }
  };

  componentDidMount() {
    const imagesTopRow = backend.getHomeImagesTop();
    const imagesBottomRow = backend.getHomeImagesBottom();

    this.setState({ imagesTopRow, imagesBottomRow }, this.checkDom);

    window.addEventListener("resize", this.resizeListener);
    window.addEventListener("load", this.resetDynamicContainer);
  }

  componentWillUnmount() {
    if (this.delay) {
      clearTimeout(this.delay);
    }
    if (this.resizeId) {
      clearTimeout(this.resizeId);
    }
    window.removeEventListener("load", this.resetDynamicContainer);
    window.removeEventListener("resize", this.resizeListener);
  }

  render() {
    return (
      <React.Fragment>
        <div style={this.state.dynamicContainer.top}></div>
        <div id="main-container" className="main-container">
          <div className="section-container">
            {this.state.imagesTopRow.map((image, i) => {
              return (
                <div className="category-container" key={i}>
                  <Link to={image.route}>
                    <span className="category-container__line">
                      <h2 className="category-container__title">
                        {image.category}
                      </h2>
                    </span>
                    <img
                      className="category-container__image"
                      src={process.env.PUBLIC_URL + image.image}
                      alt={`${image.category} Category`}
                    />
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="section-container">
            {this.state.imagesBottomRow.map((image, i) => {
              return (
                <div className="category-container" key={i}>
                  <Link to={image.route}>
                    <span className="category-container__line">
                      <h2 className="category-container__title">
                        {image.category}
                      </h2>
                    </span>
                    <img
                      className="category-container__image"
                      src={process.env.PUBLIC_URL + image.image}
                      alt={`${image.category} Category`}
                    />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <div style={this.state.dynamicContainer.bottom}></div>
      </React.Fragment>
    );
  }
}

export default Home;
