import React from "react";

const Services = () => {
  return (
    <div className="info-container">
      <h1 className="info-container__heading">MTA Services</h1>
      <p className="info-container__paragraph">
        MTA is here to assist you in obtaining top quality entertainment for
        your special event. We do all the footwork for you. Whether your event
        is small, medium, or large, call MTA... we are The Live Entertainment
        Professionals!
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">The Silver Dollar Show</span>
        <br />
        Consists of country bands, bluegrass musicians, cloggers, Western
        singing groups, stuntmen, gunfighter performers, and saloon gals.
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">South of the Border Show</span>
        <br />
        Mariachi strolling musicians, Mexican bands, Spanish dance groups,
        flamenco guitarists. All acts dress according to theme.
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Classic Rock Show</span>
        <br />
        Consisting of bands that play music of the 50's, 60's, 70's, 80's, and
        Elvis Presley impersonators.
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">South Pacific Show</span>
        <br />
        Authentic Hawaiian and Polynesian bands, fire dancers, Hula and island
        dancers.
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Roaring 20's Show</span>
        <br />
        Choose from Dixieland bands, banjo and ragtime piano players.
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Novelty Acts</span>
        <br />
        Magicians, comedians, clowns, mimes, jugglers, ventriloquists,
        caricaturists, face painters, celebrity look a likes, Mr. and Mrs. Santa
        Claus and Christmas carolers.
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Musical Entertainment</span>
        <br />
        Single guitar &amp; piano players, duos, orchestras, classic rock bands,
        djs, extreme variety &amp; ethnic bands.
      </p>
    </div>
  );
};

export default Services;
