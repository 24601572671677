import React from "react";
import { NavLink } from "react-router-dom";
import BackTop from "./design/backTop";

const NavBar = () => {
  return (
    <div className="nav-container">
      <BackTop />
      <NavLink
        to="/home"
        className="nav-container__link"
        activeClassName="active"
      >
        Home
      </NavLink>
      <NavLink
        to="/services"
        className="nav-container__link"
        activeClassName="active"
      >
        Services
      </NavLink>
      <NavLink
        to="/entertainment"
        className="nav-container__link"
        activeClassName="active"
      >
        Entertainment
      </NavLink>
      {/* <NavLink
        to="/events"
        className="nav-container__link"
        activeClassName="active"
      >
        Events
      </NavLink> */}

      <NavLink
        to="/about"
        className="nav-container__link"
        activeClassName="active"
      >
        About
      </NavLink>
      <NavLink
        to="/contact"
        className="nav-container__link"
        activeClassName="active"
      >
        Contact
      </NavLink>
    </div>
  );
};

export default NavBar;
