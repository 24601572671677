import React from "react";

const Quote = () => {
  return (
    <header>
      <p className="quote">
        We look forward to knowing you, working with you, and together exploring
        all
        <br />
        the possibilities that abound when talented people come together to do
        great things.
      </p>

      <p className="quote-mobile">
        We look forward to knowing you, working with you, and together exploring
        all the possibilities that abound when talented people come together to
        do great things.
      </p>
    </header>
  );
};

export default Quote;
