import React, { Component } from "react";
import GA from "./components/workers/GoogleAnalytics";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import FooterProvider from "./components/footerContext";
import Header from "./components/header";
import Footer from "./components/footer";
import Categories from "./components/categories";
import BlackLine from "./components/design/blackLine";
import FiveLines from "./components/design/fiveLines";
import Quote from "./components/design/quote";
import Entertainment from "./components/entertainment";
import About from "./components/about";
import Contact from "./components/contact";
import Services from "./components/services";
import Home from "./components/home";
import Search from "./components/search";
import NavBar from "./components/navBar";
import MobileMenu from "./components/mobileMenu";
import "./App.scss";
import SearchResults from "./components/searchResults";

class App extends Component {
  state = { footRef: null, mobMenuStatus: "closed" };

  footRef = (ref) => {
    console.log("APP FOOT REF: ", ref);
    this.setState({ footRef: ref });
  };

  toggleMobMenu = () => {
    const mobMenuStatus =
      this.state.mobMenuStatus === "closed" ? "open" : "closed";
    console.log("MOB MENU STATUS: ", mobMenuStatus);
    this.setState({ mobMenuStatus });
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ mobMenuStatus: "closed" });
    }
  }

  render() {
    return (
      <FooterProvider>
        <React.Fragment>
          <GA.RouteTracker />
          <Header />
          <Search />
          <BlackLine position="black-line--top" />
          <Quote />
          <FiveLines
            status={this.state.mobMenuStatus}
            toggle={this.toggleMobMenu}
            position="top"
          />
          <MobileMenu
            status={this.state.mobMenuStatus}
            toggle={this.toggleMobMenu}
          />
          <Switch>
            {/* <Route path="/home" component={Home} /> */}
            <Route
              path="/home"
              render={(props) => (
                <Home footRef={this.state.footRef} {...props} />
              )}
            />
            <Route path="/services" component={Services} />
            <Route path="/entertainment" component={Entertainment} />
            <Route
              path="/about"
              render={(props) => (
                <About footRef={this.state.footRef} {...props} />
              )}
            />
            <Route
              path="/contact"
              render={(props) => (
                <Contact footRef={this.state.footRef} {...props} />
              )}
            />
            <Route
              path="/bands"
              render={(props) => (
                <Categories footRef={this.state.footRef} {...props} />
              )}
            />
            <Route
              path="/musicians"
              render={(props) => (
                <Categories footRef={this.state.footRef} {...props} />
              )}
            />
            <Route
              path="/novelty"
              render={(props) => (
                <Categories footRef={this.state.footRef} {...props} />
              )}
            />
            <Route
              path="/impersonators"
              render={(props) => (
                <Categories footRef={this.state.footRef} {...props} />
              )}
            />
            <Route
              path="/actors"
              render={(props) => (
                <Categories footRef={this.state.footRef} {...props} />
              )}
            />
            <Route
              path="/models"
              render={(props) => (
                <Categories footRef={this.state.footRef} {...props} />
              )}
            />
            <Route
              path="/comedians"
              render={(props) => (
                <Categories footRef={this.state.footRef} {...props} />
              )}
            />
            <Route
              path="/dancers"
              render={(props) => (
                <Categories footRef={this.state.footRef} {...props} />
              )}
            />
            <Route
              path="/search"
              render={(props) => (
                <SearchResults
                  key={props.location.key}
                  footRef={this.state.footRef}
                  {...props}
                />
              )}
            />
            <Redirect from="/" exact to="/home" />
          </Switch>
          <FiveLines />
          <NavBar />
          <BlackLine position="black-line--bottom" />
          <Footer getRef={this.footRef} />
        </React.Fragment>
      </FooterProvider>
    );
  }
}

export default withRouter(App);
