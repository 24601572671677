import React from "react";
import MobileMenuButton from "../mobileMenuButton";

const FiveLines = (props) => {
  //const position = props.position ? props.position : "";
  //return <div className={`red-line ${style}`}></div>;
  return props.position === "top" ? (
    <React.Fragment>
      <MobileMenuButton status={props.status} toggle={props.toggle} />
      <div className="five-lines--1 five-lines--top"></div>
      <div className="five-lines--2 five-lines--top"></div>
      <div className="five-lines--3 five-lines--top"></div>
      <div className="five-lines--4 five-lines--top"></div>
      <div className="five-lines--5"></div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="five-lines--5"></div>
      <div className="five-lines--4 five-lines--bottom"></div>
      <div className="five-lines--3 five-lines--bottom"></div>
      <div className="five-lines--2 five-lines--bottom"></div>
      <div className="five-lines--1 five-lines--bottom"></div>
    </React.Fragment>
  );
};

export default FiveLines;
