import React, { Component } from "react";

class MobileMenuButton extends Component {
  state = {};
  render() {
    return (
      <div
        data-id="mob-listener"
        className="mob-menu-circle"
        onClick={this.props.toggle}
      >
        <div data-id="mob-listener" className="mob-menu-inner-line-1"></div>
        <div data-id="mob-listener" className="mob-menu-inner-line-2"></div>
        <div data-id="mob-listener" className="mob-menu-inner-line-3"></div>
      </div>
    );
  }
}

export default MobileMenuButton;
