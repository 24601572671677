import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Lunr from "lunr";
import backend from "./workers/tempBackend";

class Search extends Component {
  state = { value: "" };

  formSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted: ", e.target.search.value);

    if (e.target.search.value !== "") {
      const location = {
        pathname: "/search",
        search: e.target.search.value,
        state: { query: e.target.search.value },
      };

      e.target.search.value = "";

      this.props.history.push(location);
      //this.props.history.replace(location);
    }
  };

  // formChange = (e) => {
  //   this.setState({ value: e.target.value });
  //   //console.log("Value: ", this.state.value);
  // };

  render() {
    return (
      <form onSubmit={this.formSubmit}>
        <input
          className="search-container__input"
          type="text"
          name="search"
          placeholder="Search"
          //onChange={this.formChange}
        />
        <input
          type="image"
          alt="Submit"
          className="search-container__icon"
          src={`${process.env.PUBLIC_URL}images/search_icon.svg`}
        />
      </form>
    );
  }
}

export default withRouter(Search);
