export function footerPositionCalc(context, bottomOnly) {
  console.log("FOOTFIX CONTEXT VAR: ", context);
  const { offsetTop, offsetHeight } = context.current;
  const position = offsetTop + offsetHeight;
  const screenHeight = window.innerHeight;
  let difference = screenHeight - position;

  let style = {
    top: {},
    bottom: {},
  };

  const diffSplit = difference / 2;
  console.log("DIFFERENCE: ", difference);

  if (bottomOnly) {
    console.log("Bottom Only");
    style = { height: `${difference}px`, width: "100%" };

    return style;
  }

  if (difference > 0 && difference !== screenHeight) {
    style.top = { height: `${diffSplit}px`, width: "100%" };
    style.bottom = { height: `${diffSplit}px`, width: "100%" };

    return style;
  } else {
    style.top = { height: "0px", width: "100%" };
    style.bottom = { height: "0px", width: "100%" };

    return style;
  }
}

export default {
  footerPositionCalc,
};
