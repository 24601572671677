import React, { Component, createRef } from "react";
import { NavLink } from "react-router-dom";

class MobileMenu extends Component {
  state = {};

  node = createRef();

  handleClick = (e) => {
    if (this.node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    console.log("CLICKED OUTSIDE: ", this.props.status);
    console.log("CLICKED OUTSIDE EVENT: ", e);
    if (this.props.status === "open" && e.target.dataset.id !== "mob-listener")
      this.props.toggle();
  };

  componentDidMount() {
    console.log("MOUNTING: ", this.props.status);
    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  render() {
    const animateLink =
      this.props.status === "closed" ? "" : " mob-nav-link-animate";
    const animateLine =
      this.props.status === "closed" ? "" : " mob-nav-line-animation";

    return (
      <div ref={this.node} className="mob-nav-container">
        <div className={`mob-nav-link${animateLink}`}>
          <NavLink to="/home" activeClassName="mob-active">
            Home
          </NavLink>
        </div>
        <div className={`mob-nav-line-1${animateLine}`}></div>
        <div className={`mob-nav-link${animateLink}`}>
          <NavLink to="/services" activeClassName="mob-active">
            Services
          </NavLink>
        </div>
        <div className={`mob-nav-line-2${animateLine}`}></div>
        <div className={`mob-nav-link${animateLink}`}>
          <NavLink to="/entertainment" activeClassName="mob-active">
            Entertainment
          </NavLink>
        </div>
        <div className={`mob-nav-line-3${animateLine}`}></div>
        <div className={`mob-nav-link${animateLink}`}>
          <NavLink to="/about" activeClassName="mob-active">
            About
          </NavLink>
        </div>
        <div className={`mob-nav-line-4${animateLine}`}></div>
        <div className={`mob-nav-link${animateLink}`}>
          <NavLink to="/contact" activeClassName="mob-active">
            Contact
          </NavLink>
        </div>
        <div className={`mob-nav-line-5${animateLine}`}></div>
      </div>
    );
  }
}

export default MobileMenu;
