import React from "react";
import Logo from "./design/logo";
import HeaderLines from "./design/headerLines";

const Header = () => {
  return (
    <header>
      <div className="header">
        <div className="header__container header__container--left">
          <HeaderLines />
        </div>
        <Logo />
        <div className="header__container header__container--right">
          <HeaderLines />
        </div>
      </div>
    </header>
  );
};

export default Header;
