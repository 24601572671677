const homeImages = [
  [
    { category: "Bands", image: "/images/band_thumbnail.png", route: "/bands" },
    {
      category: "Musicians",
      image: "/images/musician_thumbnail.png",
      route: "/musicians",
    },
    {
      category: "Novelty Acts",
      image: "/images/novelty_thumbnail.png",
      route: "/novelty",
    },
    {
      category: "Impersonators",
      image: "/images/impersonator_thumbnail.png",
      route: "/impersonators",
    },
  ],
  [
    {
      category: "Acting Talent",
      image: "/images/acting_thumbnail.png",
      route: "/actors",
    },
    {
      category: "Models",
      image: "/images/model_thumbnail.png",
      route: "/models",
    },
    {
      category: "Comedians",
      image: "/images/comedian_thumbnail.png",
      route: "/comedians",
    },
    {
      category: "Dance Troupes",
      image: "/images/dance_thumbnail.png",
      route: "/dancers",
    },
  ],
];

const titles = {
  bands: {
    title: "Bands",
    byline: "",
    info: [
      "MTA represents over 100 bands and has produced 57 national concerts. We will work with you and your budget to book emerging artists and stars on the world stage. No matter the size of your event, we have the right performers for you.",
    ],
  },
  musicians: {
    title: "Musicians",
    byline: "",
    info: [
      "MTA works with incredibly talented musicians whom are masters of their craft. Our vast selection of performers specialize in numerous musical styles and disciplines.",
    ],
  },
  novelty: {
    title: "Novelty Acts",
    byline: "",
    info: [
      "MTA is a full-service agency that never stops recruiting talented professionals to captivate and entertain. We deliver first-rate headline acts and supporting performers to enhance your special events.",
    ],
  },
  impersonators: {
    title: "Impersonators",
    byline: "",
    info: [
      "MTA delivers professional impersonators guaranteed to make you do a double take. People love interacting with our dopplegangers as they portray celebrity characters.",
    ],
  },
  actors: {
    title: "Acting Talent",
    byline: "",
    info: [
      "MTA is franchised by SAG-AFTRA (Screen Actors Guild - American Federation of Television and Radio Artists) and represents both union and non-union members.",
    ],
  },
  models: {
    title: "Models",
    byline: "",
    info: [
      "MTA models are highly motivated professionals with a positive attitude. We carefully select team players that will communicate effectively and consistently execute as directed. In an industry where time is money, punctuality and reliability are qualities you can rely on from our talent.",
    ],
  },
  comedians: {
    title: "Comedians",
    byline: "",
    info: [
      "MTA is equipped to provide talent for major performances as well as small-scale venues. Our extensive roster of hilarious comedians will make your event a night to remember.",
    ],
  },
  dancers: {
    title: "Dance Troupes",
    byline: "",
    info: [
      "Our dance troupes and companies offer world-class performances rich with culture and spirit. The crowd will love watching as our unforgettable dancers display inspiring precision in authentic dress.",
    ],
  },
};

const categories = {
  bands: [
    {
      name: "Rock",
      title: "Rock",
      description:
        "MTA has a wide range of rock bands that play everything from rock & roll of the 1950s all the way up to the latest in current rock today.",
      subCategory: [
        "Classic",
        "Current",
        "50s",
        "60s",
        "70s",
        "80s",
        "90s",
        "2000s",
      ],
      keywords: [
        "rock",
        "roll",
        "cover",
        "classic",
        "current",
        "band",
        "bands",
        "alternative",
        "punk",
        "metal",
        "heavy metal",
        "hard rock",
        "electric guitar",
        "guitar",
        "1950",
        "1960",
        "1970",
        "1980",
        "1990",
        "2000",
        "local",
        "music",
        "musician",
        "concert",
      ],
      image: "rock_temp.jpg",
      parent: "bands",
      hasTalent: true,
    },
    {
      name: "Country",
      title: "Country",
      description:
        "Our country music bands include some of the best musicians and singers that Colorado has to offer.",
      subCategory: ["Classic Country", "Bluegrass"],
      keywords: [
        "music",
        "musician",
        "band",
        "bands",
        "concert",
        "country",
        "western",
        "cowboy",
        "memphis",
        "tennessee",
        "american",
        "usa",
        "nashville",
        "singer",
        "acoustic",
        "guitar",
        "bluegrass",
        "outlaw",
        "country rock",
        "country pop",
        "cmt",
      ],
      image: "country_temp.jpg",
      parent: "bands",
      hasTalent: true,
    },
    {
      name: "Jazz",
      title: "Jazz",
      description:
        "MTA jazz bands and ensembles perform a variety of styles suitable for all types of venues.",
      subCategory: ["Dixieland", "Swing", "Jazz Blues"],
      keywords: [
        "jazz",
        "blues",
        "horns",
        "smooth",
        "new orleans",
        "swing",
        "big",
        "band",
        "bands",
        "rhythm",
        "fusion",
        "dixieland",
        "traditional",
        "saxophone",
        "trumpet",
        "funk",
        "piano",
        "music",
        "musician",
        "concert",
      ],
      image: "jazz_dark_temp.jpg",
      parent: "bands",
      hasTalent: true,
    },
    {
      name: "Folk",
      title: "Folk",
      description:
        "MTA folk musicians and groups play those American classics that take us back and remind us simpler times.",
      subCategory: [],
      keywords: [
        "folk",
        "music",
        "musician",
        "band",
        "bands",
        "concert",
        "contemporary",
        "traditional",
        "acoustic guitar",
        "acoustic",
        "guitar",
        "cover",
        "singer",
        "classics",
        "james taylor",
        "john denver",
        "cat stevens",
      ],
      image: "folk_temp.jpg",
      parent: "bands",
      hasTalent: true,
    },
    {
      name: "Latin",
      title: "Latin",
      description:
        "Our Latin bands create a fun and spirited atmosphere while playing traditional music on a variety of instruments.",
      subCategory: [],
      keywords: [
        "latin",
        "mariachi",
        "mexican",
        "mexico",
        "band",
        "bands",
        "acoustic",
        "guitar",
        "strolling",
        "spanish",
        "trumpet",
        "world music",
        "world",
        "music",
        "musician",
        "culture",
        "violin",
        "fiddle",
      ],
      image: "latin_temp.jpg",
      parent: "bands",
      hasTalent: true,
    },
    {
      name: "Variety",
      title: "Variety",
      description:
        "Our variety groups love to entertain and are sure to deliver a memorable performance.",
      subCategory: ["R&B", "Soul", "Motown", "Funk"],
      keywords: [
        "variety",
        "rnb",
        "r&b",
        "rhythm",
        "blues",
        "soul",
        "band",
        "bands",
        "dance",
        "funk",
        "cover",
        "motown",
        "jazz",
        "contemporary",
        "1950",
        "1960",
        "1970",
        "1980",
        "1990",
      ],
      image: "variety_temp.jpg",
      parent: "bands",
      hasTalent: true,
    },
  ],
  musicians: [
    {
      name: "Guitarists",
      title: "Guitarists",
      description: "Temp text",
      subCategory: [
        "Classic Rock",
        "Country",
        "Blues",
        "Classical",
        "Folk",
        "Spanish",
      ],
      keywords: [
        "guitarist",
        "guitar",
        "singer",
        "sing",
        "acoustic",
        "solo",
        "banjo",
        "single",
        "musician",
        "music",
        "lounge",
        "club",
        "restaurant",
        "flamenco",
        "country",
        "western",
        "classic rock",
        "classic",
        "rock",
        "folk",
        "spanish",
        "cover",
        "songs",
        "classical",
        "blues",
        "1950",
        "1960",
        "1970",
        "1980",
        "1990",
      ],
      image: "guitarist_temp.jpg",
      parent: "musicians",
      hasTalent: true,
    },
    {
      name: "Pianists",
      title: "Pianists",
      description: "Temp text",
      subCategory: ["Classical", "Contemporary", "Jazz", "Blues"],
      keywords: [
        "pianist",
        "piano player",
        "piano",
        "solo",
        "single",
        "musician",
        "music",
        "classical",
        "contemporary",
        "lounge",
        "club",
        "restaurant",
        "jazz",
        "blues",
        "cover",
        "songs",
        "keyboard",
        "ragtime",
        "rag-time",
        "singer",
        "sing",
      ],
      image: "piano_temp.jpg",
      parent: "musicians",
      hasTalent: true,
    },
    {
      name: "Singers",
      title: "Singers",
      description: "Temp text",
      subCategory: [],
      keywords: [
        "singer",
        "solo",
        "single",
        "duo",
        "duet",
        "trio",
        "folk",
        "music",
      ],
      image: "singer_temp.jpg",
      parent: "musicians",
      hasTalent: true,
    },
    {
      name: "DJs",
      title: "DJs",
      description: "Temp text",
      subCategory: [
        "Rock",
        "R&B",
        "Hip-Hop",
        "Dance",
        "Country Western",
        "EDM",
      ],
      keywords: [
        "Disc",
        "Jockey",
        "DJ",
        "D.J.",
        "Deejay",
        "Rock",
        "Rap",
        "RnB",
        "R&B",
        "Rhythm",
        "Blues",
        "Techno",
        "Dance",
        "Electronic",
        "Country",
        "Western",
        "Wedding",
        "Club",
        "Night Club",
        "Nightclub",
        "EDM",
        "Hip-Hop",
        "Hiphop",
        "music",
      ],
      image: "dj_temp.jpg",
      parent: "musicians",
      hasTalent: true,
    },
  ],
  novelty: [
    {
      name: "Face Painters",
      title: "Face Painters",
      description: "Temp text",
      subCategory: [],
      keywords: [
        "face",
        "paint",
        "novelty",
        "artist",
        "art",
        "draw",
        "carnival",
        "fun",
        "family",
        "party",
        "event",
        "kids",
        "adults",
        "children",
        "child",
        "birthday",
      ],
      image: "face_painter_temp.jpg",
      parent: "novelty",
      hasTalent: true,
    },
    {
      name: "Magicians",
      title: "Magicians",
      description: "Temp text",
      subCategory: [],
      keywords: [
        "magician",
        "magic",
        "illusion",
        "show",
        "comedy",
        "comedian",
        "hypnotist",
        "hypnotize",
        "tricks",
        "novelty",
        "performer",
        "perform",
        "carnival",
        "fun",
        "family",
        "party",
        "event",
        "kids",
        "children",
        "child",
        "birthday",
      ],
      image: "magician_temp.jpg",
      parent: "novelty",
      hasTalent: true,
    },
    {
      name: "Jugglers",
      title: "Jugglers",
      description: "Temp text",
      subCategory: [],
      keywords: [
        "juggler",
        "juggle",
        "show",
        "acrobat",
        "performer",
        "perform",
        "parade",
        "costume",
        "trick",
        "novelty",
        "carnival",
        "fun",
        "family",
        "party",
        "event",
        "kids",
        "children",
        "child",
        "birthday",
      ],
      image: "juggler_temp.jpg",
      parent: "novelty",
      hasTalent: true,
    },
    {
      name: "Ventriloquists",
      title: "Ventriloquists",
      description: "Temp text",
      subCategory: [],
      keywords: [
        "ventriloquist",
        "dummy",
        "performer",
        "perform",
        "comedy",
        "comedian",
        "show",
        "novelty",
        "fun",
        "family",
        "party",
        "event",
        "kids",
        "children",
        "child",
        "birthday",
      ],
      image: "novelty_thumbnail.png",
      parent: "novelty",
      hasTalent: true,
    },
  ],
  impersonators: [
    {
      name: "Jack Nicholson",
      title: "Jack Nicholson",
      description: "Temp text",
      subCategory: [],
      keywords: [
        "impersonator",
        "impersonate",
        "celebrity",
        "doppleganger",
        "tribute",
        "look-alike",
        "look",
        "alike",
        "double",
        "star",
        "novelty",
        "event",
        "performer",
        "perform",
        "party",
        "fun",
        "hollywood",
        "movie",
        "famous",
        "fame",
      ],
      image: "impersonator_thumbnail.png",
      parent: "impersonator",
      hasTalent: true,
    },
  ],
  actors: [
    {
      name: "Male Kids & Teens",
      title: "Male Kids & Teens Actors",
      description: "Temp text",
      subCategory: [],
      image: "kids_male_actors_temp.jpg",
      keywords: [
        "kids",
        "male",
        "boys",
        "children",
        "teens",
        "young",
        "handsome",
        "attractive",
        "actor",
        "acting",
        "movie",
        "commercial",
        "play",
        "film",
        "sag",
        "screen actor guild",
        "screen",
        "guild",
        "aftra",
        "stage",
        "hollywood",
      ],
      hasTalent: true,
      parent: "actors",
      route: "",
    },
    {
      name: "Male 20s",
      title: "Male 20s Actors",
      description: "Temp text",
      subCategory: [],
      image: "20s_male_actors_cass_braido.jpg",
      keywords: [
        "adult",
        "male",
        "20",
        "men",
        "young",
        "handsome",
        "attractive",
        "actor",
        "acting",
        "movie",
        "commercial",
        "play",
        "film",
        "sag",
        "screen actor guild",
        "screen",
        "guild",
        "aftra",
        "stage",
        "hollywood",
      ],
      hasTalent: true,
      parent: "actors",
      route: "",
    },
    {
      name: "Male 30s",
      title: "Male 30s Actors",
      description: "Temp text",
      subCategory: [],
      image: "30s_male_actors_temp.jpg",
      keywords: [
        "adult",
        "male",
        "30",
        "men",
        "young",
        "handsome",
        "attractive",
        "actor",
        "acting",
        "movie",
        "commercial",
        "play",
        "film",
        "sag",
        "screen actor guild",
        "screen",
        "guild",
        "aftra",
        "stage",
        "hollywood",
      ],
      hasTalent: true,
      parent: "actors",
      route: "",
    },
    {
      name: "Male 40+",
      title: "Male 40+ Actors",
      description: "Temp text",
      subCategory: [],
      image: "40s_male_actors_temp.jpg",
      keywords: [
        "adult",
        "male",
        "40",
        "men",
        "middle age",
        "older",
        "old",
        "handsome",
        "attractive",
        "actor",
        "acting",
        "movie",
        "commercial",
        "play",
        "film",
        "sag",
        "screen actor guild",
        "screen",
        "guild",
        "aftra",
        "stage",
        "hollywood",
      ],
      hasTalent: true,
      parent: "actors",
      route: "",
    },
    {
      name: "Female Kids & Teens",
      title: "Female Kids & Teens Actresses",
      description: "Temp text",
      subCategory: [],
      image: "kids_female_actors_temp.jpg",
      keywords: [
        "kids",
        "female",
        "girls",
        "children",
        "teens",
        "young",
        "actress",
        "acting",
        "beautiful",
        "pretty",
        "gorgeous",
        "movie",
        "commercial",
        "play",
        "film",
        "sag",
        "screen actor guild",
        "screen",
        "guild",
        "aftra",
        "stage",
        "hollywood",
      ],
      hasTalent: true,
      parent: "actors",
      route: "",
    },
    {
      name: "Female 20s",
      title: "Female 20s Actresses",
      description: "Temp text",
      subCategory: [],
      image: "20s_female_actors_temp.jpg",
      keywords: [
        "adult",
        "female",
        "20",
        "women",
        "young",
        "actress",
        "acting",
        "beautiful",
        "pretty",
        "gorgeous",
        "movie",
        "commercial",
        "play",
        "film",
        "sag",
        "screen actor guild",
        "screen",
        "guild",
        "aftra",
        "stage",
        "hollywood",
      ],
      hasTalent: true,
      parent: "actors",
      route: "",
    },
    {
      name: "Female 30s",
      title: "Female 30s Actresses",
      description: "Temp text",
      subCategory: [],
      image: "30s_female_actors_temp.jpg",
      keywords: [
        "adult",
        "female",
        "30",
        "women",
        "young",
        "actress",
        "acting",
        "beautiful",
        "pretty",
        "gorgeous",
        "movie",
        "commercial",
        "play",
        "film",
        "sag",
        "screen actor guild",
        "screen",
        "guild",
        "aftra",
        "stage",
        "hollywood",
      ],
      hasTalent: true,
      parent: "actors",
      route: "",
    },
    {
      name: "Female 40+",
      title: "Female 40+ Actresses",
      description: "Temp text",
      subCategory: [],
      image: "40s_female_actors_temp.jpg",
      keywords: [
        "adult",
        "female",
        "40",
        "women",
        "middle age",
        "older",
        "old",
        "actress",
        "acting",
        "beautiful",
        "pretty",
        "gorgeous",
        "movie",
        "commercial",
        "play",
        "film",
        "sag",
        "screen actor guild",
        "screen",
        "guild",
        "aftra",
        "stage",
        "hollywood",
      ],
      hasTalent: true,
      parent: "actors",
      route: "",
    },
  ],
  models: [
    {
      name: "Male Kids & Teens",
      title: "Male Kids & Teens Models",
      description: "Temp text",
      subCategory: [],
      image: "kids_models_temp.jpg",
      keywords: [
        "kids",
        "male",
        "boys",
        "children",
        "teens",
        "young",
        "model",
        "handsome",
        "attractive",
        "print",
        "billboard",
        "magazine",
        "ad",
        "advertising",
        "advertise",
      ],
      hasTalent: true,
      parent: "models",
      route: "",
    },
    {
      name: "Male 20s",
      title: "Male 20s Models",
      description: "Temp text",
      subCategory: [],
      image: "20s_models_temp.jpg",
      keywords: [
        "men",
        "male",
        "20",
        "young",
        "model",
        "handsome",
        "attractive",
        "print",
        "billboard",
        "magazine",
        "ad",
        "advertising",
        "advertise",
      ],
      hasTalent: true,
      parent: "models",
      route: "",
    },
    {
      name: "Male 30s",
      title: "Male 30s Models",
      description: "Temp text",
      subCategory: [],
      image: "30s_models_temp.jpg",
      keywords: [
        "men",
        "male",
        "30",
        "young",
        "model",
        "handsome",
        "attractive",
        "print",
        "billboard",
        "magazine",
        "ad",
        "advertising",
        "advertise",
      ],
      hasTalent: true,
      parent: "models",
      route: "",
    },
    {
      name: "Male 40+",
      title: "Male 40+ Models",
      description: "Temp text",
      subCategory: [],
      image: "40s_models_temp.jpg",
      keywords: [
        "men",
        "male",
        "40",
        "middle age",
        "older",
        "old",
        "model",
        "handsome",
        "attractive",
        "attractive",
        "print",
        "billboard",
        "magazine",
        "ad",
        "advertising",
        "advertise",
      ],
      hasTalent: true,
      parent: "models",
      route: "",
    },
    {
      name: "Female Kids & Teens",
      title: "Female Kids & Teens Models",
      description: "Temp text",
      subCategory: [],
      image: "kids_female_models_temp.jpg",
      keywords: [
        "kids",
        "female",
        "girls",
        "children",
        "teens",
        "young",
        "model",
        "beautiful",
        "pretty",
        "gorgeous",
        "attractive",
        "print",
        "billboard",
        "magazine",
        "ad",
        "advertising",
        "advertise",
      ],
      hasTalent: true,
      parent: "models",
      route: "",
    },
    {
      name: "Female 20s",
      title: "Female 20s Models",
      description: "Temp text",
      subCategory: [],
      image: "20s_female_models_temp.jpg",
      keywords: [
        "women",
        "female",
        "20",
        "young",
        "model",
        "beautiful",
        "pretty",
        "gorgeous",
        "attractive",
        "print",
        "billboard",
        "magazine",
        "ad",
        "advertising",
        "advertise",
      ],
      hasTalent: true,
      parent: "models",
      route: "",
    },
    {
      name: "Female 30s",
      title: "Female 30s Models",
      description: "Temp text",
      subCategory: [],
      image: "30s_female_models_temp.jpg",
      keywords: [
        "women",
        "female",
        "30",
        "young",
        "model",
        "beautiful",
        "pretty",
        "gorgeous",
        "attractive",
        "print",
        "billboard",
        "magazine",
        "ad",
        "advertising",
        "advertise",
      ],
      hasTalent: true,
      parent: "models",
      route: "",
    },
    {
      name: "Female 40+",
      title: "Female 40+ Models",
      description: "Temp text",
      subCategory: [],
      image: "40s_female_models_temp.jpg",
      keywords: [
        "women",
        "female",
        "40",
        "middle age",
        "older",
        "old",
        "model",
        "beautiful",
        "pretty",
        "gorgeous",
        "attractive",
        "print",
        "billboard",
        "magazine",
        "ad",
        "advertising",
        "advertise",
      ],
      hasTalent: true,
      parent: "models",
      route: "",
    },
  ],
  comedians: [
    // {
    //   name: "Kids & Teens",
    //   description: "Temp text",
    //   subCategory: [],
    //   image: "kids_models_temp.jpg",
    //   hasTalent: true,
    //   route: "",
    // },
  ],
  dancers: [
    // {
    //   name: "Kids & Teens",
    //   description: "Temp text",
    //   subCategory: [],
    //   image: "kids_models_temp.jpg",
    //   hasTalent: true,
    //   route: "",
    // },
  ],
};

export function getHomeImagesTop() {
  return homeImages[0];
}

export function getHomeImagesBottom() {
  return homeImages[1];
}

export function getAllCategories() {
  return categories;
}

export function getCategories(category) {
  return categories[category];
}

export function getSubCategory(category) {
  return categories[category];
}

export function getTitles(title) {
  return titles[title];
}

export default {
  getHomeImagesTop,
  getHomeImagesBottom,
  getAllCategories,
  getCategories,
  getTitles,
  categories,
};
