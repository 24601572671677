import React from "react";

const Entertainment = () => {
  return (
    <div className="info-container">
      <h1 className="info-container__heading">MTA Entertainment</h1>
      <p className="info-container__paragraph">
        Music and Entertainment for Conventions, Meetings, Reunions, Weddings
        and Special Events
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Classic Rock Bands</span>
        <br />
        Music of the 50's, 60's, 70's &amp; 80's, 4 to 8 piece
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Country &amp; Bluegrass</span>
        <br />3 to 8 piece groups
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Blues &amp; Jazz Bands</span>
        <br />3 to 6 piece groups
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Duos &amp; Trios</span>
        <br />
        Variety of all types of music
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Singles Entertainers</span>
        <br />
        Piano, guitar, violin and harp
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Orchestras</span>
        <br />5 to 10 piece, music of the 1940's to the 1960's
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Specialty Music</span>
        <br />
        Mexican bands, Dixieland bands, Hawaiian &amp; Polynesian music, reggae
        bands, German bands, strolling mariachi music, society orchestras and
        classical groups
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Professional DJs</span>
        <br />
        Includes sound, lights and an extensive library of music
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Novelty Acts</span>
        <br />
        Magicians, comedians, clowns, Western gunfighters, jugglers,
        caricaturists, face painters, Mr. &amp; Mrs. Santa Claus and
        ventriloquists
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">National Concert Acts</span>
        <br />
        Prices range according to the act and scheduling
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Singing Groups</span>
        <br />
        Barbershop quartets, gospel and Christmas carolers
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Dance Troupes</span>
        <br />
        Ethnic types in authentic costumes
      </p>
      <p className="info-container__paragraph">
        <span className="info-container__span">Extreme Variety Bands</span>
        <br />
        Music of the 40's through the 80's
      </p>
    </div>
  );
};

export default Entertainment;
