import React, { Component } from "react";
import { FooterContext } from "./footerContext";
import FooterLines from "./design/footerLines";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.footer = React.createRef();
  }
  static contextType = FooterContext;

  handleLoad = () => {
    //this.context.updateRef(this.footer);
    this.props.getRef(this.footer);
  };

  componentDidMount() {
    window.addEventListener("load", this.handleLoad);
  }

  render() {
    return (
      <footer>
        <div id="footer" className="footer" ref={this.footer}>
          <div className="footer__container footer__container--left">
            <FooterLines />
          </div>
          <div className="footer__text-wrap">
            <span className="footer__text">Colorado Springs, Colorado</span>
          </div>
          <div className="footer__container footer__container--right">
            <FooterLines />
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

// const Footer = () => {
//   // static contextType = FooterContext;

//   const { value, updateRef } = useContext(FooterContext);

//   const footer = React.createRef();

//   const height = window.innerHeight;
//   //const foot = document.getElementById(foot);
//   const foot = footer;
//   // let footTop;
//   // footTop = foot ? foot.offsetTop : "";
//   console.log("WINDOW HEIGHT: ", height);
//   console.log("FOOT TOP: ", foot);
//   console.log("CONTEXT VALUE: ", value);
//   //updateRef();

//   return (
//     <footer>
//       <div id="footer" className="footer" ref={footer}>
//         <div className="header__container header__container--left">
//           <HeaderLines />
//         </div>
//         <div className="footer__text-wrap">
//           <span className="footer__text">MTA Talent Agency</span>
//         </div>
//         <div className="header__container header__container--right">
//           <HeaderLines />
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;
