import React from "react";

const HeaderLines = () => {
  return (
    <React.Fragment>
      <div className="header-line header-line-1"></div>
      <div className="header-line header-line-2"></div>
      <div className="header-line header-line-3"></div>
      <div className="header-line header-line-4"></div>
      <div className="header-line header-line-5"></div>
    </React.Fragment>
  );
};

export default HeaderLines;
